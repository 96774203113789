import React from "react";
import styled from "styled-components";
import Icon from "components/icons";
import LegacyIcon from "../icons/legacy-icon";

const links = [
  {
    id: 2,
    text: "Privacy",
    redirectUrl: "https://www.mars.com/privacy",
  },
  {
    id: 3,
    text: "Your Privacy Choices",
    redirectUrl: "https://privacyportal-eu.onetrust.com/webform/5f2759ff-9efe-412c-a3be-f13092d2c945/98373dac-6247-48a6-a190-f0094af43ee8",
    iconName: "privacyoptions",
  },
  {
    id: 4,
    text: "Cookies",
    redirectUrl: "https://www.mars.com/cookies-english",
  },
  {
    id: 5,
    text: "Accessibility",
    redirectUrl: "https://www.mars.com/accessibility",
  },
  {
    id: 6,
    text: "AdChoices",
    redirectUrl: "https://www.mars.com/mars-incorporated-adchoices-united-states",
  },
  {
    id: 7,
    text: "Cookies Settings",
    onClick: (e) => {
      e.preventDefault();
      window.Osano.cm.showDrawer("osano-cm-dom-info-dialog-open");
    },
  },
  {
    id: 8,
    text: "Contact Us",
    redirectUrl: "mailto:support@goodfriend.com?subject=Contact GoodFriend",
  },
];

const socialLinks = [
  {
    id: 1,
    iconName: "sn-instagram-20",
    redirectUrl: "https://instagram.com/goodfriend",
    ariaLabel: "Instagram",
  },
  {
    id: 2,
    iconName: "sn-facebook-20",
    redirectUrl: "https://www.facebook.com/goodfriendbykinship",
    ariaLabel: "Facebook",
  },
];

const Footer = styled.footer`
  background-color: ${({ theme }) => theme.colors.white};
  border-top: 0.0625rem solid ${({ theme }) => theme.colors.gray};
  display: grid;
  flex-direction: column;
  padding: 2.5rem 1.5rem 2.5rem;
  text-align: center;
  width: 100vw;

  ${({ theme }) => theme.mq.md`
    flex-direction: row;
    padding: 2rem 1.5rem 2.5rem;
  `}

  ${({ theme }) => theme.mq.xxl`
    grid-template-columns: 56rem auto auto;
    padding: 2rem 1.5rem;
  `}
`;

const Links = styled.div`
  display: block;
  grid-row: 1;
  margin: 0 auto 2rem;

  ${({ theme }) => theme.mq.md`
    display: flex;
    grid-column: 1;
    flex-wrap: wrap;
    margin: 0 0 0.75rem;
    max-width: 30rem;
  `}

  ${({ theme }) => theme.mq.xxl`
    align-items:center;
    margin: 0 0 1rem;
    max-width: 100%;
  `}
`;

const LinkWrapper = styled.div`
  margin: 0 0 1.25rem;

  :last-of-type {
    margin: 0;
  }

  ${({ theme }) => theme.mq.xxl`
      margin: 0;
  `}
`;

const Link = styled.a`
  color: ${({ theme }) => theme.themeColors.darkGreen4};
  display: block;
  font-size: 0.875rem;
  line-height: 1;

  :hover {
    text-decoration: underline;
  }

  ${({ theme }) => theme.mq.md`
    margin: 0 1.5rem 0 0;
  `}
`;

const IconWrapper = styled.span`
  margin: 0 0 0 0.5rem;
`;

const MarsCopyrightWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  grid-row: 3;

  ${({ theme }) => theme.mq.md`
    flex-direction: row;
    justify-content: flex-start;
  `}
`;

const Copyright = styled.p`
  display: flex;
  color: ${({ theme }) => theme.themeColors.darkGreen3};
  font-size: ${({ theme }) => theme.fontSize(1)};
  justify-content: center;
  margin: 0;
  opacity: 0.6;

  ${({ theme }) => theme.mq.md`
    justify-content: flex-start;

    br {
      display: none
    }
  `}
`;

const SocialMedia = styled.div`
  grid-row: 2;
  margin: 0 0 2rem;
  position: relative;
  width: 100%;

  ${({ theme }) => theme.mq.md`
    align-items: flex-start;
    display: flex;
    grid-column: 2;
    grid-row: 1;
    justify-content: flex-end;
    margin: 0;
  `};

  ${({ theme }) => theme.mq.xl`
    grid-column: 3;
  `};
`;

const ButtonWrapper = styled.button`
  background-color: transparent;
  border: none;
  color: ${({ theme }) => theme.themeColors.darkGreen4};
  cursor: pointer;
  margin: 0 1rem;
  padding: 0;

  :focus {
    outline: 0;
  }

  :focus-visible {
    outline: 0.125rem solid #0070f3;
  }

  ${({ theme }) => theme.mq.md`
    margin: 0 0 0 2rem;
  `};
`;

const UnstyledButton = styled(ButtonWrapper)`
  display: block;
  font-size: 0.875rem;
  line-height: 1.2;
  margin: 0 auto;

  :hover {
    text-decoration: underline;
  }

  ${({ theme }) => theme.mq.md`
    margin: 0 1.5rem 0 0;
  `};
`;

const GlobalFooter = React.forwardRef(() => {
  return (
    <Footer>
      <Links>
        {links.map((item) => {
          return (
            <LinkWrapper key={item.id}>
              {(item?.redirectUrl && (
                <Link
                  href={item?.redirectUrl}
                  target="_blank"
                  rel="noreferrer"
                >
                  {item.text}
                  {!!item?.iconName && (
                    <IconWrapper>
                      <Icon name={item?.iconName} />
                    </IconWrapper>
                  )}
                </Link>
              )) || <UnstyledButton onClick={item.onClick}>{item.text}</UnstyledButton>}
            </LinkWrapper>
          );
        })}
      </Links>
      <MarsCopyrightWrapper>
        <Copyright>
          &#169; {new Date().getFullYear()} Kinship Partners Inc.,
          <br /> an affiliate of Mars Incorporated.
        </Copyright>
      </MarsCopyrightWrapper>
      <SocialMedia>
        {socialLinks.map((item) => {
          return (
            <a
              key={item.id}
              href={item.redirectUrl}
              rel="noreferrer"
              target="_blank"
            >
              <ButtonWrapper aria-label={item.ariaLabel}>
                <LegacyIcon name={item.iconName} />
              </ButtonWrapper>
            </a>
          );
        })}
      </SocialMedia>
    </Footer>
  );
});

GlobalFooter.propTypes = {};

GlobalFooter.defaultProps = {};

export default GlobalFooter;
