import React from "react";
import styled from "styled-components";
import Image from "next/image";
import Button from "components/Button";
import GlobalFooter from "components/GlobalFooter";

const HeroWrapper = styled.section`
  background-color: ${({ theme }) => theme.themeColors.lightGreen1};
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 3rem 1.5rem 1.5rem;
  width: 100%;

  ${({ theme }) => theme.mq.lg`
    padding: 5rem 2rem 5rem;
  `}

  ${({ theme }) => theme.mq.xl`
    padding: 6.75rem 1.5rem 6.75rem;
  `}
`;

const InnerWrapper = styled.div`
  margin: 0 auto;
  max-width: 42.5rem;

  ${({ theme }) => theme.mq.lg`
    display:flex;
    justify-content: space-between;
    max-width: 77rem;
  `}
`;

const HeroTitle = styled.h1`
  font-size: 3rem;
  line-height: 1.1;

  ${({ theme }) => theme.mq.lg`
    font-size: 3.8125rem;
  `}
`;

const HeroSummary = styled.p`
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.388;
  margin: 0 0 1rem;

  ${({ theme }) => theme.mq.lg`
    font-size: 1.25rem;
  `}
`;

const ImageWrapper = styled.div`
  align-self: center;
  height: calc((100vw - 4rem) / (75 / 49));
  margin: 2.5rem auto 0;
  padding: 0;
  position: relative;
  width: 100%;

  ${({ theme }) => theme.mq.lg`
    height: 28.875rem;
    margin: 2.4375rem 0 1.125rem 1.375rem;
    max-width: 50vw;
    width: 44.25rem;
  `}
`;

const BrandsWrapper = styled.div`
  margin: 0 auto;
  max-width: 42.5rem;
  padding: 3rem 1.5rem 0;

  ${({ theme }) => theme.mq.lg`
    align-items: center;
    column-gap: 7.5rem;
    display: flex;
    padding: 5rem 3rem;
    max-width: 74.625rem;
    text-align: center;
  `}

  ${({ theme }) => theme.mq.xl`
    column-gap: 7.5rem;
    padding: 5rem 0;
  `}
`;

const Brand = styled.div`
  margin: 0 0 3.75rem;

  ${({ theme }) => theme.mq.lg`
    margin: 0;
  `}
`;

const BrandImage = styled.div`
  height: calc((100vw - 3rem) / (100 / 63));
  margin: 0 auto 1.5rem;
  max-height: 25rem;
  max-width: 39.5rem;
  position: relative;

  ${({ theme }) => theme.mq.lg`
    height: 15.75rem;
    margin: 0 auto 2.5rem;
    width: 25rem;
  `}
`;

const BrandSummary = styled(HeroSummary)`
  line-height: 1.388;

  ${({ theme }) => theme.mq.lg`
    font-weight: bold;
  `}
`;

const ButtonStyled = styled(Button)`
  font-size: 1.125rem;
  height: 3.375rem;
  margin: 1.5rem 0 0;
  width: 11.5rem;

  ${({ theme }) => theme.mq.lg`
    margin: 1.5rem auto 0;
  `}
`;

const HelpWrapper = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.themeColors.neutral};
  display: flex;
  flex-direction: column;
  height: 15.625rem;
  justify-content: center;
  padding: 0 1.5rem;
  text-align: center;

  ${({ theme }) => theme.mq.lg`
    height: 21.75rem;
  `}
`;

const HelpTitle = styled.h2`
  width: 20.3125rem;

  ${({ theme }) => theme.mq.lg`
    font-size: 3rem;
    width: 48rem;
  `}
`;

const HelpSummary = styled(HeroSummary)`
  a {
    font-size: 1.25rem;
    font-weight: 400;
    text-decoration: underline;
  }
`;

export default function CustomerSplashPage() {
  return (
    <>
      <HeroWrapper>
        <InnerWrapper>
          <div>
            <HeroTitle>Thanks for using GoodFriend!</HeroTitle>
            <HeroSummary>
              We've made some good friends, seen some great Poodle cuts, and learned a lot about how to help pet parents and pros. Thanks
              for being a part of it!
            </HeroSummary>
            <HeroSummary>
              But, like an Afghan Hound in a grooming sesh, GoodFriend is evolving. So we're saying goodbye for now while we work on new
              ways to help people care for pets. (More to come on that!)
            </HeroSummary>
            <HeroSummary>
              While we're away, check out some of our sister brands. They're also all about helping pets and their people.
            </HeroSummary>
          </div>
          <div>
            <ImageWrapper>
              <Image
                alt="Illustration of pets and parents playing in a park in front of a GoodFriend groomer"
                layout="fill"
                src="/hero_illustration.png"
                objectFit="contain"
                priority
              />
            </ImageWrapper>
          </div>
        </InnerWrapper>
      </HeroWrapper>
      <BrandsWrapper>
        <Brand>
          <BrandImage>
            <Image
              alt="Person lovingly scratching a greyhound's chin"
              layout="fill"
              objectFit="contain"
              src="/AAP.png"
            />
          </BrandImage>
          <BrandSummary>
            Adopt a Pet is one platform that connects hopeful pet parents, shelters, rescues, and rehomers—all with the goal of helping
            people and pets find each other, by improving the adoption process for everyone involved.
          </BrandSummary>
          <BrandSummary>So looking to find the right pet for you? Head their way!</BrandSummary>
          <a
            href="https://www.adoptapet.com/?utm_medium=referral&utm_source=goodfriend&utm_campaign=goodfriend_site"
            target="blank"
          >
            <ButtonStyled>Learn More</ButtonStyled>
          </a>
        </Brand>
        <Brand>
          <BrandImage>
            <Image
              alt="A dog licking a person's face while sitting on their lap"
              layout="fill"
              objectFit="contain"
              src="/the_wildest.png"
            />
          </BrandImage>
          <BrandSummary>
            The Wildest is an online destination that brings together culture, care, and content to help real people pet parent (and keep
            their cool)— with the help of product recs, vet advice, training tips, articles, interviews, and more.
          </BrandSummary>
          <BrandSummary>Check them out to help ace it for your animal (all for free).</BrandSummary>
          <a
            href="https://www.thewildest.com/?utm_medium=referral&utm_source=goodfriend&utm_campaign=goodfriend_site"
            target="blank"
          >
            <ButtonStyled>Learn More</ButtonStyled>
          </a>
        </Brand>
      </BrandsWrapper>
      <HelpWrapper>
        <HelpTitle>Need anything? We’re here to help.</HelpTitle>
        <HelpSummary>
          Email us <a href="mailto:help@goodfriend.com">here</a>.
        </HelpSummary>
      </HelpWrapper>
      <GlobalFooter />
    </>
  );
}
